import Model from './base';

export class Category extends Model {
  resource() {
    return 'catalog/category';
  }

  primaryKey() {
    return 'slug';
  }

  defaultAttributes() {
    return {
      slug: null,
      sort_order: null,
      parent: null,
      subcategories: [],
      category_translations: [],
      category_seo_translations: [],
    }
  }
}
